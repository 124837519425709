
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { getDeletedStatusCode } from "@/utils/status";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class DeleteUserModal extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  user: Api.User;
  userStatus: Api.UserStatus = null;

  loading = false;


  async deleteUser() {
    this.loading = true;
      const res = await this.$apollo.mutate({
        mutation: require("@/graphql/deleteUserFinal.graphql"),
        variables: { guid: [this.user.guid] },
      });
      this.loading = false;
      if (res.data.deleteUserFinal) {
        this.$emit("success");
        this.showSuccess("Utente eliminato definitivamente");
        this.onClose();
      } else {
        this.showError("Errore durante eliminazione utente");
      }
  }

  onClose() {
    this.$emit("close");
  }
}
