
import AssociateCompanyToUserModal from "@/components/AssociateCompanyToUserModal.vue";
import AssociateReferentToUserModal from "@/components/AssociateReferentToUserModal.vue";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import ChangeUserStatusModal from "@/components/ChangeUserStatusModal.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import DeleteUserModal from "@/components/DeleteUserModal.vue";
import DeleteUserFinalModal from "@/components/DeleteUserFinalModal.vue";
import HandleUserAssociatingModal from "@/components/HandleUserAssociatingModal.vue";
import MakeUserTeacherModal from "@/components/MakeUserTeacherModal.vue";
import Modal from "@/components/Modal.vue";
import SuspendUserModal from "@/components/SuspendUserModal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import { RoutesNames } from "@/router";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { associateData, getReferentName } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import {
  checkUserStatus,
  isDeleted,
  isSuspended,
  StatusCodes,
} from "../utils/status";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    CreateUserModal,
    ChangeUserStatusModal,
    SuspendUserModal,
    DeleteUserModal,
    AssociateCompanyToUserModal,
    HandleUserAssociatingModal,
    UsersSelector,
    CompaniesSelector,
    AssociateReferentToUserModal,
    MakeUserTeacherModal,
    DeleteUserFinalModal
  },
  watch: {
    users(val, prev) {
      this.search = "";
      this.usersShown = val;
    },
    search(val) {
      this.filterUsers();
    },
    filters: {
      handler(val) {
        this.filterUsers();
      },
      deep: true,
    },
  },
})
export default class UsersTable extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @State((state: StateStore) => state.data.users) users;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedUsers;

  loading = false;

  @Prop({ default: true })
  showSelect: boolean;
  @Prop({ default: true })
  showFilters: boolean;

  @Prop({ default: false })
  isSuspendedTable: boolean;

  usersShown = [];

  statusFilter: Api.UserStatus[] = [];
  associateFilter = associateData;
  search = "";
  filters = {
    status: [],
    referent: null,
    company: null,
    associated: [],
  };

  associatingUser = null;
  showAssociatingUserModal = null;

  showAssociatingCompanyToUserModal = null;

  sendingEmailUser = null;
  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  showManageUserReferentModal = null;
  editingUser = null;

  status = [];
  userStatus = null;
  changingStatusUser = null;
  showChangingStatusUserModal = null;

  resettingPasswordUser = null;
  showResettingPasswordUserModal = null;

  suspendingUser = [];
  showSuspendingUserModal = null;

  deletingUser = [];
  showDeletingUserModal = null;

  deleteFinalUser = null;
  showDeleteFinalUserModal = null;

  showMakeUserTeacherModal = null;

  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Referente",
      align: "start",
      sortable: false,
      value: "referent",
    },
    {
      text: "Azienda",
      align: "start",
      sortable: false,
      value: "company",
    },
    {
      text: "Tipologia",
      align: "start",
      sortable: false,
      value: "status",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  mounted() {
    this.initFilters();
  }

  getModalKey() {
    return Math.random();
  }

  initFilters() {
    this.statusFilter.push(this.getStatus(StatusCodes.USER_DIP_AZ));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_REF_AZ));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_ACTIVE));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_SLEEPY));
    this.statusFilter.push(this.getStatus(StatusCodes.USER_PASSIVE));

    this.filters.status = _.map(this.statusFilter, "code");
    this.filters.associated = _.map(this.associateFilter, "value");
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  initAssociatingUser(user) {
    this.setSelectedUsers([user]);
    this.showAssociatingUserModal = true;
  }

  initAssociatingCompany(user) {
    this.setSelectedUsers([user]);
    this.showAssociatingCompanyToUserModal = true;
  }

  initManageReferent(user) {
    this.editingUser = user;
    this.showManageUserReferentModal = true;
    if (!_.isNil(user.facilitator)) {
      this.setSelectedUsers([user.facilitator]);
    } else {
      this.setSelectedUsers([]);
    }
  }

  initMakeUserTeacher(user) {
    this.editingUser = user;
    this.showMakeUserTeacherModal = true;
  }

  filterUsers() {
    let textFilteredUsers = this.users;
    if (!!this.search && this.search.length > 0) {
      textFilteredUsers = this.users.filter((u) => {
        if (
          u.name.toLowerCase().includes(this.search.toLowerCase()) ||
          u.surname.toLowerCase().includes(this.search.toLowerCase()) ||
          u.email.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    }

    const statusFilteredUsers = _.filter(this.users, (user) => {
      return checkUserStatus(user, this.filters.status);
    });

    let referentFilteredUsers = this.users;
    if (!_.isNil(this.filters.referent)) {
      referentFilteredUsers = _.filter(this.users, (user) => {
        return user.facilitator?.guid == this.filters.referent.guid;
      });
    }

    let companyFilteredUsers = this.users;
    if (!_.isNil(this.filters.company)) {
      companyFilteredUsers = _.filter(this.users, (user) => {
        return user["company_guid"] == this.filters.company.guid;
      });
    }

    const associateFilteredUsers = _.filter(this.users, (user) => {
      return this.filters.associated.includes(user.associated);
    });

    this.usersShown = _.intersectionBy(
      textFilteredUsers,
      statusFilteredUsers,
      referentFilteredUsers,
      companyFilteredUsers,
      associateFilteredUsers,
      "guid"
    );
  }

  getRolesFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }

  hasReferentName(user) {
    return !_.isEmpty(getReferentName(user));
  }

  getReferentName(user) {
    if (this.hasReferentName(user)) {
      return getReferentName(user);
    }
    return "Nessun referente";
  }

  hasCompany(user) {
    return user.company != null;
  }

  getCompanyName(user) {
    if (this.hasCompany(user)) {
      return user.company.ragsoc;
    }
    return "Nessuna azienda collegata";
  }

  getStatusLabel(code) {
    return this.getStatus(code)?.des;
  }

  getRoleTypeLabel(user) {
    if (isSuspended(user.status?.code) || isDeleted(user.status?.code)) {
      if (checkUserRole(user, [RoleCodes.USER])) {
        return "Utente";
      }
      if (checkUserRole(user, [RoleCodes.ADMIN2])) {
        return "Amministratore Liv.2";
      }
    } else return user.status?.des;
  }

  setAssociatingUser(item) {
    this.associatingUser = item;
    this.showAssociatingUserModal = item;
  }

  getAssociatingOrNot() {
    if (this.associatingUser == null) {
      return "";
    } else {
      return this.associatingUser.associated ? "dissociare" : "associare";
    }
  }

  async toggleAssociateUser() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $associated: Int) {
          updateUser(guid: $guid, associated: $associated)
        }
      `,
      variables: {
        guid: this.associatingUser.guid,
        associated: this.associatingUser.associated == 1 ? 0 : 1,
      },
    });
    this.loading = false;
    this.setAssociatingUser(null);
    if (res.data.updateUser) {
      this.showSuccess("Associazione utente modificata correttamente");
    } else {
      this.showError("Errore associazione utente");
    }
    this.updateUsers();
  }

  setSending(item) {
    if (_.isNil(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailToUser(
          $guid: ID!
          $subject: String!
          $text: String!
        ) {
          sendEmailToUser(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: this.selected[0].guid,
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailToUser) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  setResettingPassword(item) {
    this.resettingPasswordUser = item;
    this.showResettingPasswordUserModal = item;
  }

  setDeleteFinal(item) {
    this.deleteFinalUser = item;
    this.showDeleteFinalUserModal = item;
  }

  handleClick(item) {
    this.$router.push({
      name: RoutesNames.manageUsersDetails,
      params: { userGuid: item.guid },
    });
  }

  async resetUserPassword() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation resetPassword($guid: ID!) {
          resetPassword(guid: $guid)
        }
      `,
      variables: { guid: this.resettingPasswordUser.guid },
    });
    this.loading = false;
    this.setResettingPassword(null);
    if (res.data.resetPassword) {
      this.showSuccess("Password reset completato");
    } else {
      this.showError("Errore reset password");
    }
  }

  showChangeStatusModal(item) {
    this.changingStatusUser = item;
    this.showChangingStatusUserModal = item;
  }

  setSuspending(items) {
    this.suspendingUser = items;
    this.showSuspendingUserModal = items.length == 0 ? null : items;
  }

  setDeleting(items) {
    this.deletingUser = items;
    this.showDeletingUserModal = items.length == 0 ? null : items;
  }

  updateUsers() {
    this.$emit("update");
  }

  isSuspended(code) {
    return isSuspended(code);
  }

  isDeleted(code) {
    return isDeleted(code);
  }
}
