
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import UsersTable from "@/components/UsersTable.vue";
import CandidateUsersTable from "@/components/CandidateUsersTable.vue";
import AdminsLiv2Table from "@/components/AdminsLiv2Table.vue";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import _ from "lodash";
import { StatusCodes } from "@/utils/status";

@Component({
  components: {
    Btn,
    Card,
    CreateUserModal,
    UsersTable,
    AdminsLiv2Table,
    CandidateUsersTable,
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class ManageUsersView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action fetchRolesList;
  @Action getUsers;
  @Action getCandidateUsers;
  @Mutation setUsers;
  @Getter getRole: (code: string) => Api.Role;

  tabKeys = [
    "requestsTab",
    "usersTab",
    "adminsLevel2Tab",
    "adminsLevel1Tab",
    "suspendedDeletedTab",
  ];
  showCreateUserModal = null;
  roleFilter = [];
  statusFilter = [];

  async mounted() {
    if (checkUserRole(this.user, [RoleCodes.ADMIN2])) {
      this.tabKeys.splice(this.tabKeys.indexOf("adminsLevel2Tab"), 2);
    }
    await this.fetchRolesList();
    await this.onChangeTab();
  }

  async onChangeTab() {
    const tabKey = this.$route.query.tab;
    this.roleFilter = [];
    this.statusFilter = [];
    switch (_.indexOf(this.tabKeys, tabKey)) {
      case 0: {
        await this.fetchCandidateUsers();
        break;
      }
      case 1: {
        this.roleFilter.push(this.getRole(RoleCodes.USER).guid);
        this.statusFilter.push(StatusCodes.USER_DIP_AZ);
        this.statusFilter.push(StatusCodes.USER_ACTIVE);
        this.statusFilter.push(StatusCodes.USER_SLEEPY);
        this.statusFilter.push(StatusCodes.USER_PASSIVE);
        this.statusFilter.push(StatusCodes.USER_REF_AZ);
        await this.fetchUsers();
        break;
      }
      case 2: {
        if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
          this.roleFilter.push(this.getRole(RoleCodes.ADMIN2).guid);
          this.statusFilter.push(StatusCodes.ADMIN2_RES_AREA);
          this.statusFilter.push(StatusCodes.ADMIN2_RES_SER);
        }
        if (checkUserRole(this.user, [RoleCodes.ADMIN2])) {
          this.roleFilter.push(this.getRole(RoleCodes.USER).guid);
          this.roleFilter.push(this.getRole(RoleCodes.ADMIN2).guid);
          this.statusFilter.push(StatusCodes.SUSPENDED_USER);
          this.statusFilter.push(StatusCodes.SUSPENDED_ADMIN2);
          this.statusFilter.push(StatusCodes.DELETED_USER);
          this.statusFilter.push(StatusCodes.DELETED_ADMIN2);
        }
        await this.fetchUsers();
        break;
      }
      case 3: {
        if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
          this.roleFilter.push(this.getRole(RoleCodes.ADMIN1).guid);
          this.statusFilter.push(StatusCodes.ADMIN1_ACTIVE);
        }
        await this.fetchUsers();
        break;
      }
      case 4: {
        if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
          this.roleFilter.push(this.getRole(RoleCodes.USER).guid);
          this.roleFilter.push(this.getRole(RoleCodes.ADMIN2).guid);
          this.roleFilter.push(this.getRole(RoleCodes.ADMIN1).guid);
          this.statusFilter.push(StatusCodes.SUSPENDED_USER);
          this.statusFilter.push(StatusCodes.SUSPENDED_ADMIN2);
          this.statusFilter.push(StatusCodes.SUSPENDED_ADMIN1);
          this.statusFilter.push(StatusCodes.DELETED_USER);
          this.statusFilter.push(StatusCodes.DELETED_ADMIN2);
          this.statusFilter.push(StatusCodes.DELETED_ADMIN1);
        }
        await this.fetchUsers();
        break;
      }
    }
  }

  async fetchUsers() {
    const filter = {
      roleFilter: this.roleFilter,
      statusFilter: this.statusFilter,
    };
    await this.getUsers(filter);
  }

  async fetchCandidateUsers() {
    await this.getCandidateUsers();
  }

  isAdminLevel1() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1]);
  }

  isAdminLevel2() {
    return checkUserRole(this.user, [RoleCodes.ADMIN2]);
  }
}
