
import * as Api from "@/types/graphql";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { StatusCodes } from "@/utils/status";
import { RoleCodes } from "@/utils/roles";
import { parseDate, formatForApi } from "@/plugins/moment";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    UsersSelector,
  },
  watch: {
    users(val, prev) {
      this.search = "";
      this.usersShown = val;
    },
    search() {
      this.filterUsers();
    },
    filters: {
      handler(val) {
        this.filterUsers();
      },
      deep: true,
    },
  },
})
export default class CandidateUsersTable extends Vue {
  @State((state: StateStore) => state.auth.user) user;
  @State((state: StateStore) => state.auth.statusList) statusList;
  @State((state: StateStore) => state.data.candidateUsers) users;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedCandidateUsers;

  usersShown = [];

  @Prop({ default: false })
  showFilters: boolean;

  search = "";

  loading = false;

  getModalKey() {
    return Math.random();
  }

  sendingEmailUser = null;
  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  acceptingUser = null;
  showAcceptUserModal = null;

  refusingUser = null;
  showRefuseUserModal = null;

  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Telefono",
      align: "start",
      sortable: false,
      value: "phone",
    },
    {
      text: "Codice Fiscale",
      align: "start",
      sortable: false,
      value: "tax_code",
    },
    {
      text: "Data di nascita",
      align: "start",
      sortable: false,
      value: "birth_date",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  capitalize(text) {
    return _.capitalize(text);
  }

  setSending(item) {
    this.sendingEmailUser = item;
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailToUser(
          $guid: ID!
          $subject: String!
          $text: String!
        ) {
          sendEmailToUser(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: this.sendingEmailUser.guid,
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailToUser) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  updateUsers() {
    this.$emit("update");
  }

  setAccepting(item) {
    this.acceptingUser = item;
    this.showAcceptUserModal = item;
  }

  setRefusing(item) {
    this.refusingUser = item;
    this.showRefuseUserModal = item;
  }

  async createUser(newUser) {
    this.loading = true;
    const birthday = parseDate(newUser["birth_date"]);
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation user_insert(
          $email: String
          $name: String
          $surname: String
          $phone: String
          $role: String
          $facilitator: String
          $status: String
          $birthday: String
          $gender: String
          $taxCode: String
          $associated: Int
          $isTeacher: Int
        ) {
          insertUser(
            email: $email
            name: $name
            surname: $surname
            phone: $phone
            role_guid: $role
            facilitator_guid: $facilitator
            userstatus_guid: $status
            birth_date: $birthday
            gender: $gender
            tax_code: $taxCode
            associated: $associated
            is_teacher: $isTeacher
          )
        }
      `,
      variables: {
        email: newUser.email,
        name: _.capitalize(newUser.name),
        surname: _.capitalize(newUser.surname),
        phone: newUser.phone,
        role: this.getRole(RoleCodes.USER).guid,
        facilitator: this.user.guid,
        status: this.getStatus(StatusCodes.USER_ACTIVE).guid,
        birthday: formatForApi(birthday),
        gender: newUser.gender,
        taxCode: newUser["tax_code"],
        associated: 0,
        isTeacher: 0,
      },
    });

    if (res.data.insertUser) {
      await this.refuseUser(this.acceptingUser, false)
      this.setAccepting(null);
      this.$emit("update");
      this.showSuccess("Utente accettato correttamente");
    } else {
      this.loading = false;
      this.showError("Errore durante accettazione utente");
    }
  }

  async refuseUser(user, showMessage) {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation deleteCandidateUser($guid: [String]) {
          deleteCandidateUser(guid: $guid)
        }
      `,
      variables: {
        guid: [user.guid],
      },
    });

    if (res.data.deleteCandidateUser) {
      this.setRefusing(null);
      this.$emit("update");
      if (showMessage) {
        this.showSuccess("Utente rifiutato correttamente");
      }
    } else {
      this.loading = false;
      this.showError("Errore");
    }
  }
}
